.b47 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon40 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon142,
.group-icon144 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon144 {
  width: 16px;
}
.frame43,
.menu-overlay40 {
  position: absolute;
  overflow: hidden;
}
.frame43 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay40 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.div200 {
  position: relative;
}
.wrapper57 {
  flex-direction: row;
  justify-content: center;
}
.button-wrapper36,
.button67,
.wrapper57 {
  display: flex;
  align-items: center;
}
.button67 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  width: 343px;
  height: 55px;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
}
.button-wrapper36 {
  top: 905px;
  left: 521px;
  background-color: var(--background);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-sm);
  justify-content: flex-end;
  font-size: var(--small-regular-size);
  color: var(--widgets);
}
.div201,
.div202 {
  position: relative;
}
.div201 {
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 327px;
}
.div202 {
  align-self: stretch;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.parent42 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.div203 {
  flex: 1;
  position: relative;
}
.frame-child81 {
  position: relative;
  width: 20px;
  height: 20px;
}
.parent43 {
  width: 594px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.input-title88 {
  position: relative;
  display: inline-block;
  width: 295px;
}
.input-wrapper74 {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  width: 295px;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.frame-parent93,
.input-wrapper74,
.input158,
.parent44 {
  display: flex;
  justify-content: flex-start;
}
.input158 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.frame-parent93,
.parent44 {
  flex-direction: row;
}
.parent44 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  width: 295px;
  padding: var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-base);
}
.frame-parent93 {
  align-items: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--medium-regular-size);
}
.input-parent19,
.list,
.list-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.input-parent19 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-base);
}
.list,
.list-inner {
  align-items: center;
  font-size: var(--small-regular-size);
}
.list {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: 0 var(--padding-base) var(--padding-base);
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--medium-regular-size);
}
.no-copy-2 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent92,
.list1 {
  display: flex;
  align-items: center;
}
.list1 {
  border-radius: var(--br-5xs);
  border: 1px dashed var(--secondary-1);
  box-sizing: border-box;
  width: 630px;
  flex-direction: row;
  padding: var(--padding-base);
  justify-content: center;
}
.frame-parent92 {
  top: 172px;
  left: 318px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.share6 {
  position: relative;
  background-color: var(--background);
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
