.b55 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon45 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon157,
.group-icon159 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon159 {
  width: 16px;
}
.frame48,
.menu-overlay45 {
  position: absolute;
  overflow: hidden;
}
.frame48 {
  float: right;
    cursor: pointer;
    position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay45 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.div221 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  height: 29px;
  flex-shrink: 0;
}
.div222,
.wrapper63,
.wrapper64 {
  display: flex;
  align-items: center;
}
.div222 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  width: 9px;
}
.wrapper63,
.wrapper64 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  width: 186px;
  height: 52px;
  flex-direction: column;
  padding: var(--padding-base);
  box-sizing: border-box;
  justify-content: center;
}
.wrapper64 {
  cursor: pointer;
}
.frame-parent110 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.div224 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.frame-parent111 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: center;
}
.frame-parent109,
.frame-wrapper11,
.parent49 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-parent109 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-wrapper11,
.parent49 {
  border-radius: var(--br-base);
}
.frame-wrapper11 {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-7xl) var(--padding-base);
  justify-content: flex-start;
  text-align: justify;
  color: var(--highlights);
}
.parent49 {
  position: absolute;
  top: 172px;
  left: 320px;
  width: 800px;
  justify-content: center;
  gap: var(--gap-5xl);
  font-size: var(--large-bold-size);
}
.continue54 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button-wrapper39,
.button74 {
  display: flex;
  align-items: center;
}
.button74 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  justify-content: center;
}
.button-wrapper39 {
  position: absolute;
  top: 897px;
  left: 0;
  background-color: var(--background);
  width: 1440px;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  justify-content: flex-start;
  color: var(--widgets);
}
.statement-intro1 {
  position: relative;
  background-color: var(--background);
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--medium-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
