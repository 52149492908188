.progress-bar-child135,
.progress-bar-child136 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child136 {
  background-color: var(--secondary-2);
}
.progress-bar31 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div183 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 343px;
}
.iconcar-empty2 {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.div184 {
  flex: 1;
  position: relative;
}
.iconcar-empty-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.radio-icon12 {
  position: relative;
  width: 20px;
  height: 20px;
}
.frame-parent88 {
  border-radius: var(--br-5xs);
  display: flex;
  width:100%;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.selection1-item {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 1px;
}
.peugeot2 {
  position: relative;
  display: inline-block;
  width: 144.5px;
  flex-shrink: 0;
}
.peugeot-frame {
  width: 144.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.div185 {
  position: relative;
}
.frame-parent89,
.wrapper55 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wrapper55 {
  width: 144.5px;
  justify-content: flex-start;
}
.frame-parent89 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  padding: var(--padding-base);
  justify-content: space-between;
}
.div186,
.div187 {
  position: absolute;
  top: 0;
  left: 0;
}
.div187 {
  left: 147px;
  display: inline-block;
  width: 134px;
}
.parent29 {
  flex: 1;
  position: relative;
  height: 19px;
}
.iconcar-empty-parent1,
.selection1-inner1,
.selection11 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.selection1-inner1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  flex-direction: row;
  padding: var(--padding-base);
}
.iconcar-empty-parent1,
.selection11 {
  gap: var(--gap-5xs);
}
.selection11 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  flex-direction: column;
}
.iconcar-empty-parent1 {
  width: 269px;
  flex-direction: row;
}
.frame-wrapper9,
.selection1-group {
  
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.selection1-group {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.frame-wrapper9 {
  flex-direction: column;
  text-align: left;
  font-size: var(--medium-regular-size);
}
.progress-bar-parent27 {
  top: 148px;
  left: 314px;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.a10 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon35 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon127,
.group-icon129 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon129 {
  width: 16px;
}
.frame38,
.menu-overlay35 {
  position: absolute;
  overflow: hidden;
}
.frame38 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay35 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue44 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button52 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.button-wrapper32,
.st-driver11 {
  background-color: var(--background);
}
.button-wrapper32 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.st-driver11 {
  position: relative;
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
.radio-item2 [type="radio"]{
  display: none;
  
}

.radio-item2 + .radio-item2{
  margin-top: 15px;
 
}
.radio-item2 label{
    display: block;
    text-align: center;
    padding: 20px 43px 20px 20px;
    background: #ffffff;
    border-radius: 6px;
    cursor: pointer;
    min-width: 250px;
    white-space: nowrap;
    position: relative;
}
.radio-item2 label:after,
.radio-item2 label:before{
  content: "";
  position: absolute;
  border-radius: 50%;
}

.radio-item2 label:after{
  height: 20px;
  width: 20px;
   border:2px solid #46bedc;
  right: 5px;
  top: calc(50% - 12px);
}
.radio-item2{
  min-width: 340px !important;
}
.radio-item2 label:before{
  background: #46bedc;
  height: 10px;
  width: 10px;
  right: 10px;
  top: calc(50% - 7px);
  transform: scale(5);
  transition: .4s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
}


.radio-item2 [type="radio"]:checked ~ label:before{
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
@media only screen and (max-width: 800px) {
.selection1-group {
  align-self: stretch;
  padding: auto;
  display: contents;
}
.selection11{
  margin:auto;
  width: 100%;
}
.radio-item2{
  min-width: 290px !important;
}
}