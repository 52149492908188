.b41 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon37 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon133,
.group-icon135 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon135 {
  width: 16px;
}
.frame40,
.menu-overlay37 {
  position: absolute;
  overflow: hidden;
}
.frame40 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay37 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.frame-child72 {
  position: relative;
  width: 16px;
  height: 16px;
}
.button-wrapper33,
.button55 {
  display: flex;
  align-items: center;
}
.button55 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  width: 343px;
  height: 55px;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
}
.button-wrapper33 {
  position: absolute;
  top: 905px;
  left: 521px;
  background-color: var(--background);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-sm);
  justify-content: flex-end;
  font-size: var(--small-regular-size);
  color: var(--widgets);
}
.iconyes3 {
  position: relative;
  width: 64px;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.b42 {
  align-self: stretch;
  font-family: var(--m3-label-large);
}
.b42,
.frame-child73,
.pdf {
  position: relative;
}
.frame-child73 {
  width: 28px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
}
.button56,
.pdf-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pdf-parent {
  align-items: center;
  gap: var(--gap-5xs);
}
.button56 {
  border-radius: var(--br-7xs);
  width: 194px;
  padding: var(--padding-sm) var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  cursor: pointer;
  font-size: var(--small-regular-size);
  color: var(--highlights);
}
.continue46 {
  flex: 1;
  position: relative;
}
.button57,
.button58,
.share {
  flex-direction: row;
  justify-content: center;
}
.button57 {
  flex: 1;
  border-radius: var(--br-7xs);
  background-color: var(--secondary-2);
  display: none;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
}
.button58,
.share {
  display: flex;
}
.button58 {
  flex: 1;
  border-radius: var(--br-7xs);
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  font-size: var(--small-regular-size);
  color: var(--highlights);
}
.share {
  width: 107px;
  align-items: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.iconyes-parent1,
.parent37 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.parent37 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.iconyes-parent1 {
  position: absolute;
  top: 172px;
  left: 320px;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  width: 800px;
  height: 563px;
  padding: var(--padding-13xl) var(--padding-45xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xl);
  font-size: var(--font-size-lgi);
}
.saved {
  position: relative;
  background-color: var(--background);
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
