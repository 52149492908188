.progress-bar-child157,
.progress-bar-child160 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child160 {
  background-color: var(--secondary-2);
}
.progress-bar37 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  z-index: 4;
}
.div340,
.div341 {
  position: relative;
}
.div340 {
  line-height: 20px;
  font-weight: 600;
}
.div341 {
  align-self: stretch;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.div339 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.div338,
.div339,
.div342 {
  align-self: stretch;
}
.div342 {
  position: relative;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
  display: none;
}
.div338 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  z-index: 3;
  text-align: center;
  font-size: var(--large-bold-size);
}
.div343,
.icon38 {
  position: relative;
}
.icon38 {
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.div343 {
  flex: 1;
}
.frame-child101 {
  position: relative;
  width: 20px;
  height: 20px;
}
.icon-parent20 {
  width:100%;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.input-title185 {
  position: relative;
}
.input-wrapper163 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
}
.input-parent47,
.input342 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.input342 {
  flex: 1;
  width:100%;
  flex-direction: column;
}
.input-parent47 {
  align-self: stretch;
  flex-direction: row;
}
.input-iconcalendar22,
.input349 {
  position: relative;
  overflow: hidden;
}
.input349 {
  flex: 1;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 19px;
}
.input-iconcalendar22 {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.input350,
.qwerty1-parent14 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.qwerty1-parent14 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
}
.input350 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
.input-title190 {
  position: relative;
  display: inline-block;
}
.input352 {
  flex: 1;
  position: relative;
  opacity: 0;
}
.input-wrapper167,
.input-wrapper170 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--tint-blueish-grey-extra-light);
}
.input-wrapper170 {
  overflow: hidden;
  flex-shrink: 0;
  color: var(--secondary-1);
}
.frame-parent156,
.input-parent49,
.input357 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.input357 {
  width: 295px;
  display: none;
  flex-direction: column;
}
.frame-parent156,
.input-parent49 {
  display: flex;
}
.input-parent49 {
  align-self: stretch;
  flex-direction: row;
  color: var(--color-gray-100);
}
.frame-parent156 {
  width: 100%;
  flex-direction: column;
  font-size: var(--small-regular-size);

}
.div344,
.icondriver29 {
  position: relative;
  flex-shrink: 0;
}
.div344 {
  display: inline-block;
  width: 309px;
  height: 16px;
}
.icondriver29 {
  width: 42px;
  height: 42px;
  overflow: hidden;
}
.icondriver-parent27,
.parent76 {
  align-items: center;
  justify-content: center;
}
.icondriver-parent27 {
  width: 309px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-lg);
}
.parent76 {
  border-radius: var(--br-3xs);
  background-color: var(--widgets);
  display: none;
  flex-direction: column;
  padding: var(--padding-base);
  gap: var(--gap-base);
  text-align: center;
}
.div345 {
  position: relative;
  display: inline-block;
  width: 229px;
}
.wrapper95 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.slider-child27 {
  position: relative;
  border-radius: 50%;
  background-color: var(--widgets);
  width: 18px;
  height: 18px;
}
.slider29 {
  border-radius: var(--br-9xl);
  background-color: var(--highlights);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 56px;
  height: 24px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  align-items: center;
  justify-content: flex-end;
}
.frame-parent157,
.infopersonalexpand13 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  align-items: center;
  justify-content: center;
}
.frame-parent157 {
  width: 343px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.infopersonalexpand13 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-13xl) var(--padding-base);
  
  z-index: 2;
}
.insurance-icon5 {
  position: relative;
  border-radius: var(--br-xl);
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.div346,
.insurance-parent3 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.div346 {
  flex: 1;
  position: relative;
}
.insurance-parent3 {
  flex-direction: row;
  padding: var(--padding-base) 0;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.icon-parent21,
.infoadressexpand5,
.input-parent50 {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.input-parent50 {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-5xl);
  font-size: var(--small-regular-size);
}
.icon-parent21,
.infoadressexpand5 {
  align-items: center;
}
.infoadressexpand5 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  flex-direction: column;
  padding: 0 var(--padding-13xl) var(--padding-base);
  gap: var(--gap-base);
  z-index: 1;
}
.icon-parent21 {
  flex-direction: row;
  padding: var(--padding-base) 0;
  gap: var(--gap-3xl);
}
.input369,
.select-parent8 {
  display: flex;
  gap: var(--gap-5xs);
}
.input369 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--main);
}
.select-parent8 {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.input-parent51,
.input-wrapper178,
.select-parent8 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.input-wrapper178 {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  padding: var(--padding-smi) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.input-parent51 {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--small-regular-size);
  color: var(--color-gray-100);
}
.infolicenceexpand11,
.progress-bar-parent31 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-base);
}
.infolicenceexpand11 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: 0 var(--padding-13xl) var(--padding-base);
  justify-content: center;
  z-index: 0;
}
.progress-bar-parent31 {
  position: absolute;
  top: 148px;
  left: 320px;
  width: 800px;
  padding: var(--padding-5xl) var(--padding-base) 0;
  box-sizing: border-box;
  justify-content: flex-start;
  text-align: left;
}
.b72 {
  position: absolute;
  top: 119px;
  left: 322px;
  font-size: var(--large-bold-size);
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon60 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon199,
.group-icon201 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon201 {
  width: 16px;
}
.frame62,
.menu-overlay59 {
  position: absolute;
  overflow: hidden;
}
.frame62 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay59 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue73 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button98 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.button-wrapper51,
.nd-driver7 {
  background-color: var(--background);
}
.button-wrapper51 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  color: var(--widgets);
}
.nd-driver7 {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--medium-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
#panel1a-header {
  width: 100%;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.infopersonalexpand13.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  border-radius: 10px;
  box-shadow: none;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: transparent !important;
}
input[type=number] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 700px) {
  .input-parent47 {
    align-self: stretch;
    flex-direction: column;
}
}
#accordion {
  margin: 0px 0px 10px 0px;
}