.b80,
.wrapper104 {
  align-self: stretch;
}
.b80 {
  position: relative;
  line-height: 100%;
}
.wrapper104 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconinjured1 {
  position: relative;
  width: 56px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.div380 {
  flex: 1;
  position: relative;
}
.frame-child105 {
  position: relative;
  width: 20px;
  height: 20px;
}
.iconinjured-group {
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.input-title212 {
  align-self: stretch;
  position: relative;
}
.input-wrapper189 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.input-parent55,
.input394 {
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.input394 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.input-parent55 {
  align-self: stretch;
  flex-direction: row;
}
.frame-parent187,
.inp-frame,
.input-parent55,
.input402 {
  display: flex;
  justify-content: flex-start;
}
.inp-frame {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: var(--main);
}
.frame-parent187,
.input402 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.input402 {
  flex: 1;
  color: var(--color-gray-100);
}
.frame-parent187 {
  width: 688px;
  font-size: var(--small-regular-size);
}
.div381,
.icondriver34 {
  font-size: 0.9rem;
  position: relative;
}
.icondriver34 {
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.icondriver-parent32,
.iconpassenger-group {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-9xs);
}
.icondriver-parent32 {
  display: flex;
  align-items: center;
}
.iconpassenger-group {
  color: var(--main);
}
.frame-parent189,
.iconpassenger-group,
.parent83 {
  display: flex;
  align-items: center;
}
.frame-parent189 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--small-regular-size);
  color: var(--highlights);
}
.parent83 {
  border-radius: var(--br-3xs);
  background-color: var(--widgets);
  flex-direction: column;
  padding: var(--padding-base) 0;
  justify-content: center;
  gap: var(--gap-base);
}
.trip-data-are1 {
  align-self: stretch;
  position: relative;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
  display: none;
}
.parent84 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-child5 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--highlights);
  width: 40px;
  height: 24px;
}
.group-child6 {
  position: absolute;
  top: 2px;
  left: 18px;
  border-radius: 50%;
  background-color: var(--widgets);
  box-shadow: var(--popup);
  width: 20px;
  height: 20px;
}
.rectangle-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 24px;
}
.toggle1 {
  position: relative;
  width: 40px;
  height: 24px;
}
.frame-parent188,
.frame-parent190 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame-parent190 {
  width: 343px;
  padding: var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
}
.frame-parent188 {
  width: 668px;
  align-items: center;
}
.div385,
.icondriver35 {
  position: relative;
  flex-shrink: 0;
}
.div385 {
  display: inline-block;
  width: 309px;
  height: 16px;
}
.icondriver35 {
  width: 42px;
  height: 42px;
  overflow: hidden;
}
.icondriver-parent33,
.parent85 {
  align-items: center;
  justify-content: center;
}
.icondriver-parent33 {
  width: 309px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-lg);
}
.parent85 {
  border-radius: var(--br-3xs);
  background-color: var(--widgets);
  display: none;
  flex-direction: column;
  padding: var(--padding-base);
  gap: var(--gap-base);
  text-align: center;
}
.div386 {
  position: relative;
  display: inline-block;
  width: 229px;
}
.slider-child31 {
  position: relative;
  border-radius: 50%;
  background-color: var(--widgets);
  width: 18px;
  height: 18px;
}
.slider33 {
  border-radius: var(--br-9xl);
  background-color: var(--highlights);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 56px;
  height: 24px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  align-items: center;
  justify-content: flex-end;
}
.frame-parent191,
.infopersonalexpand15 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frame-parent191 {
  width: 343px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-xs);
}
.infopersonalexpand15 {
  width: 100%;
  margin:8px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-13xl) var(--padding-base);
  gap: var(--gap-base);
}
.no-copy-24 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.list5 {
  border-radius: var(--br-5xs);
  border: 1px dashed var(--secondary-1);
  box-sizing: border-box;
  width: 100%;
  display: flex;
  cursor:pointer;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: center;
  justify-content: center;
}
.infopersonalexpand-parent1 {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--medium-regular-size);
}
.frame-parent186 {
  top: 148px;
  left: 320px;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.b81 {
  position: absolute;
  top: 119px;
  left: 322px;
  font-size: var(--large-bold-size);
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon67 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon220,
.group-icon222 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon222 {
  width: 16px;
}
.frame69,
.menu-overlay66 {
  position: absolute;
  overflow: hidden;
}
.frame69 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay66 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue81 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button107 {
  position: absolute;
  top: 897px;
  left: 555px;
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.state-layer255 {
  flex-direction: row;
  padding: var(--padding-5xs);
}
.container232,
.icon-button20,
.state-layer255 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container232 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  flex-direction: row;
}
.icon-button20 {
  width: 48px;
  height: 48px;
  flex-direction: column;
}
.label-text22 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
}
.icon40 {
  position: relative;
  width: 18px;
  height: 18px;
}
.state-layer256 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-3xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
}
.month-drop-down5,
.selection-row5,
.state-layer256 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.selection-row5 {
  align-self: stretch;
  height: 64px;
  justify-content: space-between;
}
.date245 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.days-of-the-week5,
.sunday35 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sunday35 {
  border-radius: var(--br-81xl);
  width: 48px;
  height: 48px;
  align-items: center;
}
.days-of-the-week5 {
  align-self: stretch;
  align-items: flex-start;
}
.container236,
.state-layer261,
.sunday36 {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
}
.state-layer261 {
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.container236,
.sunday36 {
  align-items: flex-start;
  justify-content: flex-start;
}
.sunday36 {
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
}
.state-layer267 {
  width: 40px;
  height: 40px;
}
.container242,
.saturday36,
.state-layer267 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.container242 {
  border-radius: var(--br-81xl);
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.saturday36 {
  width: 48px;
  height: 48px;
  color: var(--main);
}
.week-15 {
  color: var(--secondary-1);
}
.week-15,
.week-65 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.range-highlight-end5,
.range-highlight-start5 {
  position: absolute;
  margin: 0 !important;
  width: 80%;
  top: calc(50% - 20px);
  right: 48.75%;
  left: -28.75%;
  background-color: var(--m3-sys-light-secondary-container);
  height: 40px;
  display: none;
  z-index: 0;
}
.range-highlight-start5 {
  right: -31.25%;
  left: 51.25%;
  z-index: 1;
}
.container258 {
  border-radius: var(--br-81xl);
  background-color: var(--highlights);
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.calendar-grid5,
.monday39 {
  display: flex;
  flex-direction: column;
}
.monday39 {
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--widgets);
}
.calendar-grid5 {
  padding: 0 var(--padding-xs) var(--padding-9xs);
  align-items: flex-end;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
}
.state-layer303 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.secondary-button5 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.local-actions5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs);
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  color: var(--highlights);
}
.continue53 {
  font-size: 1rem;
}
.date-picker5 {
  position: absolute;
  top: 484px;
  left: 840px;
  border-radius: var(--br-base);
  background: linear-gradient(rgba(70, 190, 220, 0.1), rgba(70, 190, 220, 0.1)),
    #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--small-regular-size);
  font-family: var(--m3-label-large);
}
.injured3 {
  position: relative;
  background-color: var(--background);
  width: 100%;

  overflow: hidden;
  cursor: pointer;
  text-align: center;
  font-size: var(--title-small-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
form{
  width:100%;
}
switch {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 51px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: -1px;
  border: 2px solid #46bedc;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #46bedc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}