.div391,
.div392 {
  align-self: stretch;
  position: relative;
}
.div392 {
  font-size: var(--m3-label-large-size);
  color: var(--secondary-1);
}
.showcaseyourstory {
  width: 351px;
  height: 145px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  text-align: center;
  font-size: var(--large-semibold-size);
  color: var(--main);
  font-family: var(--medium-semibold);
}
.notch-icon2 {
  width: 219px;
  height: 30px;
  position: relative;
  object-fit: cover;
  display: none;
}
.recording-indicator-icon2 {
  width: 6px;
  height: 6px;
  position: relative;
  display: none;
}
.error-ellipse,
.error-ellipse1,
.error-ellipse2 {
  height: 8px;
  margin:2px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: var(--secondary-1);
}
.error-ellipse1,
.error-ellipse2 {
  background-color: var(--highlights);
}
.error-ellipse2 {
  background-color: var(--secondary-1);
}
.circular-frames,
.error-frame,
.illustration3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.circular-frames {
  gap: var(--gap-8xs);
}
.error-frame,

.illustration3 {
  width: 100%;
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 0 0 var(--padding-36xl);
  box-sizing: border-box;
  gap: var(--gap-41xl);
  cursor: pointer;
}
