.progress-bar-child27 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div32 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 343px;
}
.text4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.div33 {
  flex: 1;
  position: relative;
}
.wrapper13 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.insideicon10 {
  position: relative;
  width: 20px;
  height: 20px;
}
.frame-parent19 {
  cursor: pointer;
}
.frame-parent19,
.frame-parent20 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: center;
  justify-content: space-between;
}
.frame-parent18,
.yesno3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frame-parent18 {
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.yesno3 {
  justify-content: space-between;
  text-align: left;
  font-size: var(--medium-regular-size);
}
.div31,
.progress-bar-parent3 {
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.progress-bar-parent3 {
  top: 172px;
  left: 320px;
  width: 100%;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  justify-content: flex-end;
  gap: var(--gap-base);
}
.b9 {
  position: absolute;
  top: 118px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon6 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon20,
.group-icon22 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon22 {
  width: 16px;
}
.frame7,
.menu-overlay6 {
  position: absolute;
  overflow: hidden;
}
.frame7 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay6 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue6 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button7 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.accident5,
.button-wrapper3 {
  background-color: var(--background);
}
.button-wrapper3 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.accident5 {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
.audio-recorder {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border-radius: 20px;
  box-sizing: border-box;
  color: #000;
  left: 20px;
  top: -10px;
  width: 40px;
  position: relative;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  transition: all .2s ease-in;
  -webkit-tap-highlight-color: transparent;
}
.audio-recorder-options {

  height: 16px;
  cursor: pointer;
  padding: 12px 6px 12px 12px;
}
.recording {
  border-radius: 12px;
  width: 240px !important;
  transition: all .2s ease-out;
}

@media only screen and (max-width: 400px) {
.audio-recorder {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border-radius: 20px;
  box-sizing: border-box;
  color: #000;
  width: 230px !important;
  justify-content: center !important;
  display: flex;
  align-items: center;
  transition: all .2s ease-in;
  -webkit-tap-highlight-color: transparent;
}
}