.div252 {
  font-weight: 600;
}
.div252,
.div253,
.driver-1-icon {
  position: relative;
}
.driver-1-icon {
  width: 36px;
  height: 36px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.wrapper75 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div255,
.div256 {
  align-self: stretch;
  position: relative;
}
.div255 {
  font-size: var(--medium-regular-size);
  display: inline-block;

  flex-shrink: 0;
}
.div256 {
  font-size: var(--xsmall-regular-size);
  color: var(--secondary-1);
}
.frame-parent128 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.previous-statement-info {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--font-size-lg);
  color: var(--main);
}
.previous-statements-card {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  display: flex;
  flex-direction: column;
  padding: var(--padding-base);
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xs);
}
.div259 {
  align-self: stretch;
  position: relative;
  font-size: var(--small-regular-size);
  display: inline-block;

  flex-shrink: 0;
}
.previous-statement-info1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--medium-regular-size);
  color: var(--main);
}
.previous-statements-card1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  display: flex;
  flex-direction: column;
  padding: var(--padding-base);
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--active);
}
.previous-statements-card-parent {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--xsmall-regular-size);
  color: var(--secondary-1);
}
.div251,
.parent56,
.previous-statements-card-parent {
  display: flex;
  flex-direction: column;
}
.parent56 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.div251 {
  margin-top: 20px;
  float:left; 
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.vector-icon89 {
  position: relative;
  width: 28px;
  height: 37px;
}
.new-statement {
  border-radius: 32px;
  background-color: var(--widgets);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs);
  align-items: center;
  justify-content: center;
}
.b62,
.div261 {
  position: relative;
  display: inline-block;
}
.b62 {
  align-self: stretch;
  line-height: 20px;
  height: 29px;
  flex-shrink: 0;
}
.div261 {
  font-size: var(--xsmall-regular-size);
  color: var(--secondary-1);
 
}
.new-statement-parent,
.parent57 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.new-statement-parent {
  gap: var(--gap-base);
}
.frame-child85 {
  position: relative;
  width: 80px;
  height: 80px;
}
.b63,
.div262 {
  align-self: stretch;
  position: relative;
}
.b63 {
  line-height: 20px;
  display: none;
  height: 29px;
  flex-shrink: 0;
}
.div262 {
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.group-parent,
.parent58 {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.parent58 {
  align-self: stretch;
  gap: var(--gap-9xs);
}
.group-parent {
  width: 796px;
  gap: var(--gap-base);
  font-size: var(--medium-regular-size);
}
.button80,
.button81 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button81 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  height: 32px;
  border:0;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  cursor: pointer;
  font-size: var(--small-regular-size);
  color: var(--widgets);
}
.button81:disabled {
  background-color: #E7E8E9 !important;
}
.button80 {
  float:left; 
  
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  width: 100%;
  height: 336px;
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-base);
  gap: var(--gap-5xl);
  font-size: var(--large-bold-size);
}

.homepage1 {
  margin-bottom: 50px;
  width: 100%;
  height: 29px;
}
.arrow-header-2-icon51,
.menu-overlay-icon {
  float: left;
  cursor: pointer;
  position: relative;
  top: -12px;
}
.menu-overlay-icon {
  float: right;
  cursor: pointer;
  position: relative;
  top: -12px;

}
.wrapperpage {
  position: relative;
  background-color: var(--background);
  max-width: 800px;
  min-width: 100%;
  padding-top: 50px;
  padding-right: 12px;
  padding-left: 12px;
  overflow: hidden;
  text-align: center;
  font-size: var(--medium-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
.MuiCollapse-root {
  width: 100%;
}
.b62, .b54, .title221{
  font-size: 15px;
}