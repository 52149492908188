.b53 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon44 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon154,
.group-icon156 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon156 {
  width: 16px;
}
.frame47,
.menu-overlay44 {
  position: absolute;
  overflow: hidden;
}
.frame47 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay44 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue53 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button-wrapper38,
.button73 {
  display: flex;
  align-items: center;
}
.button73 {
  margin-top: 20px;
  cursor: pointer;
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  flex-direction: row;
  padding: 14px;
  justify-content: center;
}
.button-wrapper38 {

  background-color: var(--background);
  width: 100%;

  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  justify-content: flex-start;
  color: var(--widgets);
}
.b54,
.icontheft {
  position: relative;
}
.b54 {
  align-self: stretch;
  line-height: 20px;
  display: inline-block;
  height: 29px;
  flex-shrink: 0;
}
.icontheft {
    width: 40px;
    float: left;
    bottom: 10px;
    height: 40px;
}
.div217 {
  flex: 1;
  position: relative;
}
.icontheft-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.radio-icon16 {
  position: relative;
  width: 20px;
  height: 20px;
}
.frame-parent104 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: var(--padding-base);
  align-items: center;
  gap: var(--gap-3xl);
}
.frame-parent103,
.frame-parent104,
.frame-parent105 {
  display: flex;
  width:100%;
  flex-direction: row;
  justify-content: flex-start;
}
.frame-parent105 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: var(--padding-base);
  align-items: center;
  gap: var(--gap-3xl);
  cursor: pointer;
}
.frame-parent103 {
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.iconshattered-glass {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent102 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-parent102,
.frame-wrapper10,
.parent48 {
  display: flex;
  flex-direction: column;
}
.frame-wrapper10 {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.parent48 {
    top: 172px;
    float: left;  
    width: 100%;
    flex-direction: column;
    left: 320px;
    border-radius: var(--br-base); 
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xl);
}
.statement-intro {
  position: relative;
  background-color: var(--background);
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--medium-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
@media only screen and (max-width: 550px) {
.frame-parent103 {
  display: contents;
}
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
}

.radio-section{
  display: flex;
  align-items: center;
  justify-content: center;

}
.radio-item [type="radio"]{
  display: none;
  
}

.radio-item + .radio-item{
  margin-top: 15px;
 
}
.radio-item label{
    display: block;
    text-align: left;
    padding: 20px 43px 20px 20px;
    background: #ffffff;
    border-radius: 6px;
    cursor: pointer;
    min-width: 250px;
    white-space: nowrap;
    position: relative;
}
.radio-item label:after,
.radio-item label:before{
  content: "";
  position: absolute;
  border-radius: 50%;
}

.radio-item label:after{
  height: 20px;
  width: 20px;
  border:2px solid #46bedc;
  right: 20px;
  top: calc(50% - 12px);
}
.radio-item{
  width: 320px !important;
}
.radio-item label:before{
  background: #46bedc;
  height: 10px;
  width: 10px;
  right: 25px;
  top: calc(50% - 7px);
  transform: scale(5);
  transition: .4s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
}
.radio-item [type="radio"]:checked ~ label{
  border:2px solid #46bedc;
}

.radio-item [type="radio"]:checked ~ label:before{
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
#wrapper{
max-width: 800px;
margin: auto;
}
.label4{
  margin-left: 10px;
}

.radio-item2 label{
  display: block;
  text-align: left;
  padding: 17px 43px 17px 20px;
  background: #E7E8E9;
  border:none;
  border-radius: 6px;
  margin: 4px 0px;
  cursor: pointer;
  min-width: 250px;
  white-space: nowrap;
  position: relative;
}
.radio-item2 label:before{
  background: #46bedc;
  height: 10px;
  width: 10px;
  right: 25px;
  top: calc(50% - 7px);
  transform: scale(5);
  transition: .4s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
}
.radio-item2 label:after{
  height: 20px;
  width: 20px;
  border:2px solid #46bedc;
  right: 20px;
  top: calc(50% - 12px);
}