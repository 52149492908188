@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/SF-Pro-Text-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/SF-Pro-Text-Semibold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/SF-Pro-Text-Bold.otf");
  font-weight: 700;
}
:root {
  --bodyColor: #eeefef;
}
body {
  margin: 0;
  background-color: var(--bodyColor);
  line-height: normal;
}
:root {
  /* fonts */
  --medium-regular: "SF Pro Text";
  --m3-label-large: Roboto;
  --font-inter: Inter;

  /* font sizes */
  --medium-regular-size: 16px;
  --small-regular-size: 14px;
  --xsmall-regular-size: 12px;
  --large-bold-size: 17px;
  --medium-regular1-size: 15px;
  --font-size-lg: 18px;
  --font-size-26xl: 45px;
  --font-size-3xs: 10px;
  --font-size-5xs: 8px;
  --font-size-7xs: 6px;
  --font-size-lgi: 19px;
  --title-small-bold-size: 20px;

  /* Colors */
  --color-4: #f9f9f9;
  --background: #eeefef;
  --highlights: #46bedc;
  --widgets: #fff;
  --outline: #929292;
  --color-gray-300: #858585;
  --text: #797474;
  --main: #242a30;
  --m3-sys-light-on-surface: #1c1b1f;
  --color-gray-100: #0f0f1e;
  --overlay: rgba(0, 0, 0, 0.3);
  --color-gray-200: rgba(0, 0, 0, 0.5);
  --black: #000;
  --color-3: #e8e8e8;
  --secondary-2: #e7e8e9;
  --secondary-1: #a5a9ac;
  --alert: #c8001e;
  --color-firebrick: #b80808;
  --color-steelblue: #0878b8;
  --tint-blueish-grey-extra-light: #c8c8d0;
  --color-1: #bebebe;
  --m3-sys-light-primary: #6750a4;
  --m3-sys-light-secondary-container: #e8def8;
  --color-2: #d4d4d4;
  --progress: #8be28a;
  --active: #6ea500;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-5xs: 8px;
  --gap-29xl: 48px;
  --gap-base: 16px;
  --gap-5xl: 24px;
  --gap-11xl: 30px;
  --gap-9xs: 4px;
  --gap-13xl: 32px;
  --gap-15xl: 34px;
  --gap-mid: 17px;
  --gap-xs: 12px;
  --gap-6xs: 7px;
  --gap-12xs: 1px;
  --gap-lg: 18px;
  --gap-3xl: 22px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-base: 16px;
  --padding-3xs: 10px;
  --padding-13xl: 32px;
  --padding-sm: 14px;
  --padding-9xs: 4px;
  --padding-lgi: 19px;
  --padding-5xs: 8px;
  --padding-12xs: 1px;
  --padding-smi: 13px;
  --padding-5xl: 24px;
  --padding-xs: 12px;
  --padding-4xs: 9px;
  --padding-45xl: 64px;
  --padding-7xl: 26px;

  /* border radiuses */
  --br-7xs: 6px;
  --br-5xs: 8px;
  --br-5xl: 24px;
  --br-9xs: 4px;
  --br-base: 16px;
  --br-11xs: 2px;
  --br-3xl: 22px;
  --br-81xl: 100px;
  --br-31xl: 50px;
  --br-3xs: 10px;
  --br-39xl: 58px;
  --br-8xs: 5px;
  --br-9xl: 28px;
  --br-xl: 20px;

  /* Effects */
  --popup: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
b, strong {
  font-weight: bolder;
  font-size: 18px;
}
@media only screen and (max-width: 500px) {
  b, strong {
    font-size: 16px;
  }
  .div228{
    font-size: 14px;

  }
}