.progress-bar-child127 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar27 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  z-index: 3;
}
.div160,
.div161 {
  position: relative;
}
.div160 {
  align-self: stretch;
  line-height: 20px;
  font-weight: 600;
}
.div161 {
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
  display: inline-block;
  width: 343px;
}
.div159,
.parent24 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.parent24 {
  width: 343px;
  gap: var(--gap-5xs);
}
.div159 {
  align-self: stretch;
  z-index: 2;
  text-align: center;
  font-size: var(--large-bold-size);
}
.div162,
.icon22 {
  position: relative;
}
.icon22 {
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.div162 {
  flex: 1;
}
.frame-child50 {
  position: relative;
  width: 20px;
  height: 20px;
}
.icon-parent8 {
  width: 688px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.input-title73 {
  align-self: stretch;
  position: relative;
}
.input-wrapper61 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
}
.input-parent15,
.input130 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.input130 {
  flex: 1;
  flex-direction: column;
}
.input-parent15 {
  align-self: stretch;
  flex-direction: row;
}
.input-iconcalendar10,
.input137 {
  position: relative;
  overflow: hidden;
}
.input137 {
  flex: 1;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 19px;
}
.input-iconcalendar10 {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.input138,
.qwerty1-parent8 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.qwerty1-parent8 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
}
.input138 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
.input-title78 {
  position: relative;
  display: inline-block;
  width: 295px;
}
.input140 {
  flex: 1;
  position: relative;
  opacity: 0;
}
.input-wrapper65,
.input-wrapper68 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--tint-blueish-grey-extra-light);
}
.input-wrapper68 {
  overflow: hidden;
  flex-shrink: 0;
  color: var(--secondary-1);
}
.frame-parent81,
.input-parent17,
.input145 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.input145 {
  width: 295px;
  display: none;
  flex-direction: column;
}
.frame-parent81,
.input-parent17 {
  display: flex;
}
.input-parent17 {
  align-self: stretch;
  flex-direction: row;
  color: var(--color-gray-100);
}
.frame-parent81 {
  width: 688px;
  flex-direction: column;
  font-size: var(--small-regular-size);
}
.div163,
.icondriver10 {
  position: relative;
  flex-shrink: 0;
}
.div163 {
  display: inline-block;
  width: 309px;
  height: 16px;
}
.icondriver10 {
  width: 42px;
  height: 42px;
  overflow: hidden;
}
.icondriver-parent8,
.parent25 {
  align-items: center;
  justify-content: center;
}
.icondriver-parent8 {
  width: 309px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-lg);
}
.parent25 {
  border-radius: var(--br-3xs);
  background-color: var(--widgets);
  display: none;
  flex-direction: column;
  padding: var(--padding-base);
  gap: var(--gap-base);
  text-align: center;
}
.div164 {
  position: relative;
  display: inline-block;
  width: 229px;
}
.wrapper47 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.slider-child8 {
  position: relative;
  border-radius: 50%;
  background-color: var(--widgets);
  width: 18px;
  height: 18px;
}
.slider10 {
  border-radius: var(--br-9xl);
  background-color: var(--highlights);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 56px;
  height: 24px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  align-items: center;
  justify-content: flex-end;
}
.frame-parent82,
.infopersonalexpand5 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  justify-content: center;
}
.frame-parent82 {
  width: 343px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-xs);
}
.infopersonalexpand5 {
  flex-direction: column;
  padding: 0 var(--padding-13xl) var(--padding-base);
  gap: var(--gap-base);
  z-index: 1;
}
.div165,
.icon-parent9,
.infopersonalexpand5 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.div165 {
  flex: 1;
  position: relative;
}
.icon-parent9 {
  flex-direction: row;
  padding: var(--padding-base) 0;
  justify-content: center;
  gap: var(--gap-3xl);
}
.input151,
.select-parent2 {
  display: flex;
  gap: var(--gap-5xs);
}
.input151 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--main);
}
.select-parent2 {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.input-parent18,
.input-wrapper73,
.select-parent2 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.input-wrapper73 {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  padding: var(--padding-smi) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.input-parent18 {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--small-regular-size);
  color: var(--color-gray-100);
}
.infolicenceexpand5,
.progress-bar-parent23 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-base);
}
.infolicenceexpand5 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: 0 var(--padding-13xl) var(--padding-base);
  justify-content: center;
  z-index: 0;
}
.progress-bar-parent23 {
  top: 148px;
  left: 320px;
  padding: var(--padding-5xl) var(--padding-base) 0;
  box-sizing: border-box;
  justify-content: flex-start;
  text-align: left;
}
.a6 {
  position: absolute;
  top: 119px;
  left: 322px;
  font-size: var(--large-bold-size);
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon31 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon115,
.group-icon117 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon117 {
  width: 16px;
}
.frame34,
.menu-overlay31 {
  position: absolute;
  overflow: hidden;
}
.frame34 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay31 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue40 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button48 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.button-wrapper28,
.st-driver7 {
  background-color: var(--background);
}
.button-wrapper28 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  color: var(--widgets);
}
.st-driver7 {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--medium-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
