.progress-bar-child81,
.progress-bar-child84 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child84 {
  background-color: var(--secondary-2);
}
.progress-bar14 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.trip-map-1-icon4 {
  position: absolute;
  width: 103.13%;
  top: calc(50% - 246.5px);
  right: -0.57%;
  left: -2.56%;
  max-width: 100%;
  overflow: hidden;
  height: 647px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-60910 {
  position: absolute;
  top: 4px;
  left: -9px;
  width: 354px;
  height: 421px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-60911 {
  position: absolute;
  top: 0;
  left: 0;
  width: 800px;
  height: 496px;
  object-fit: cover;
}
.pin-1-icon2 {
  position: absolute;
  top: 172px;
  left: 367px;
  width: 30px;
  height: 38px;
  overflow: hidden;
}
.chalandri-ag5 {
  position: relative;
}
.trip-locations5 {
  position: absolute;
  top: 8px;
  left: 10px;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.trip-map-1-parent2 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  height: 343px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-wrapper6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.vector-icon69,
.vector-icon70,
.vector-icon71,
.vector-icon72,
.vector-icon73 {
  position: absolute;
  height: 16.65%;
  width: 13.12%;
  top: 84.77%;
  right: 81.04%;
  bottom: -1.42%;
  left: 5.84%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon70,
.vector-icon71,
.vector-icon72,
.vector-icon73 {
  right: 21.05%;
  left: 65.83%;
}
.vector-icon71,
.vector-icon72,
.vector-icon73 {
  height: 10.72%;
  width: 11.23%;
  top: 44.16%;
  right: 13.98%;
  bottom: 45.11%;
  left: 74.79%;
}
.vector-icon72,
.vector-icon73 {
  right: 89.97%;
  left: -1.2%;
}
.vector-icon73 {
  height: 63.88%;
  width: 73.04%;
  top: 27.42%;
  right: 20.99%;
  bottom: 8.7%;
  left: 5.97%;
}
.vector-icon74,
.vector-icon75,
.vector-icon76,
.vector-icon77,
.vector-icon78 {
  position: absolute;
  height: 2.84%;
  width: 70.65%;
  top: 80.05%;
  right: 22.28%;
  bottom: 17.11%;
  left: 7.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon75,
.vector-icon76,
.vector-icon77,
.vector-icon78 {
  height: 11.79%;
  width: 16.77%;
  top: 64.13%;
  right: 22.78%;
  bottom: 24.08%;
  left: 60.45%;
}
.vector-icon76,
.vector-icon77,
.vector-icon78 {
  right: 75.64%;
  left: 7.6%;
}
.vector-icon77,
.vector-icon78 {
  height: 2.84%;
  width: 67.19%;
  top: 52.04%;
  right: 24.01%;
  bottom: 45.12%;
  left: 8.8%;
}
.vector-icon78 {
  height: 10.21%;
  width: 30.3%;
  top: 64.68%;
  right: 42.51%;
  bottom: 25.11%;
  left: 27.19%;
}
.group-child1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-steelblue);
}
.b27,
.ellipse-parent1 {
  position: absolute;
  top: 7.46%;
  left: 21.08%;
}
.ellipse-parent1 {
  height: 60.63%;
  width: 51.19%;
  top: 0;
  right: 0;
  bottom: 39.37%;
  left: 48.81%;
}
.group5 {
  position: relative;
  width: 31.25px;
  height: 26.39px;
}
.group-wrapper10 {
  border-radius: var(--br-3xl);
  background-color: var(--widgets);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.group-child2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-firebrick);
}
.b28 {
  position: absolute;
  top: 7.46%;
  left: 27.33%;
}
.drivers2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-child36 {
  position: relative;
  border-right: 1px solid var(--main);
  box-sizing: border-box;
  width: 1px;
  height: 31px;
}
.group-icon69 {
  position: relative;
  width: 14.75px;
  height: 25.17px;
}
.group-wrapper12 {
  border-radius: var(--br-3xl);
  background-color: var(--widgets);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.group-icon70,
.union-icon3 {
  position: relative;
  width: 27.26px;
  height: 19.53px;
}
.union-icon3 {
  width: 28px;
  height: 18.21px;
}
.bike3 {
  border-radius: var(--br-3xl);
  background-color: var(--widgets);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.motorbike-child1 {
  position: relative;
  width: 30px;
  height: 21.46px;
}
.group-icon71,
.group-icon72 {
  position: relative;
  width: 25px;
  height: 29.88px;
}
.group-icon72 {
  width: 28.42px;
  height: 25px;
}
.bus-wrapper1 {
  flex-direction: row;
  justify-content: flex-start;
}
.bus-wrapper1,
.frame-parent55,
.other3 {
  display: flex;
  align-items: flex-start;
}
.frame-parent55 {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.other3 {
  flex-direction: column;
  justify-content: center;
}
.drivers-container {
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: var(--widgets);
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.input-title8 {
  align-self: stretch;
  position: relative;
  display: none;
}
.qwerty114 {
  flex: 1;
  position: relative;
}
.search-icon3 {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.input-wrapper6,
.input11,
.qwerty1-parent2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.qwerty1-parent2 {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  gap: var(--gap-5xs);
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.input-wrapper6,
.input11 {
  flex-direction: column;
  align-items: flex-start;
}
.input11 {
  gap: var(--gap-5xs);
}
.input-wrapper6 {
  cursor: pointer;
}
.greece-warning-sign-warning-fo-icon108 {
  position: relative;
  width: 36.71px;
  height: 32px;
  object-fit: cover;
}
.sign152 {
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.greece-warning-sign-warning-fo-icon110,
.mandatory-direction-ahead-turn-icon48 {
  position: relative;
  width: 36.24px;
  height: 32px;
  object-fit: cover;
}
.mandatory-direction-ahead-turn-icon48 {
  width: 32px;
}
.sign-parent12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame-parent56,
.frame-parent57,
.frame-wrapper7 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent57 {
  align-items: flex-start;
  gap: var(--gap-mid);
}
.frame-parent56,
.frame-wrapper7 {
  align-items: center;
}
.frame-parent56 {
  gap: var(--gap-13xl);
}
.frame-wrapper7 {
  border-radius: var(--br-base);
  background-color: var(--widgets);
  height: 422px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  font-size: var(--small-regular-size);
  color: var(--main);
}
.frame-parent53,
.frame-parent54,
.progress-bar-parent12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frame-parent54 {
  align-self: stretch;
  font-size: var(--xsmall-regular-size);
  color: var(--widgets);
}
.frame-parent53,
.progress-bar-parent12 {
  width: 782px;
}
.progress-bar-parent12 {
  position: absolute;
  top: 172px;
  left: 334px;
  border-radius: var(--br-base);
  width: 800px;
  justify-content: center;
  text-align: left;
}
.b29 {
  position: absolute;
  top: 118px;
  left: 322px;
  font-size: var(--large-bold-size);
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon17 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon73,
.group-icon75 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon75 {
  width: 16px;
}
.frame20,
.menu-overlay17 {
  position: absolute;
  overflow: hidden;
}
.frame20 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay17 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue21 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button24 {
  border-radius: var(--br-7xs);
  background-color: var(--secondary-2);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.accident16,
.button-wrapper14 {
  background-color: var(--background);
}
.button-wrapper14 {
  top: 897px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.accident16 {
  position: relative;
  width: 100%;
  height: 992px;
  text-align: center;
  font-size: var(--small-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}

img#signs {
  margin: 6px;
}

.drivers-container2 {
    align-self: stretch;
    border-radius: var(--br-base);
    background-color: var(--widgets);
    overflow-x: auto;
    /* display: inline-flex; */
    width: 100%;
    /* flex-direction: row; */
    padding: var(--padding-5xs) var(--padding-base);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
}