.progress-bar-child63,
.progress-bar-child66 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child66 {
  background-color: var(--secondary-2);
}
.progress-bar11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.trip-map-1-icon1 {
  position: absolute;
  width: 103.13%;
  top: calc(50% - 246.5px);
  right: -0.57%;
  left: -2.56%;
  max-width: 100%;
  overflow: hidden;
  height: 647px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-6094 {
  position: absolute;
  top: 4px;
  left: -9px;
  width: 354px;
  height: 421px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-6095 {
  position: absolute;
  top: 0;
  left: 0;
  width: 800px;
  height: 496px;
  object-fit: cover;
}
.pin-1-icon1 {
  position: absolute;
  top: 172px;
  left: 367px;
  width: 30px;
  height: 38px;
  overflow: hidden;
}
.chalandri-ag2 {
  position: relative;
}
.trip-locations2 {
  position: absolute;
  top: 8px;
  left: 10px;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.trip-map-1-group {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  height: 343px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-icon44 {
  position: relative;
  width: 31.63px;
  height: 26.76px;
}
.drivers,
.group-wrapper {
  display: flex;
  align-items: center;
}
.group-wrapper {
  border-radius: var(--br-3xl);
  background-color: var(--widgets);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  justify-content: center;
}
.drivers {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.line-div {
  position: relative;
  border-right: 1px solid var(--main);
  box-sizing: border-box;
  width: 1px;
  height: 31px;
}
.group-icon46 {
  position: relative;
  width: 14.75px;
  height: 25.17px;
}
.group-frame {
  border-radius: var(--br-3xl);
  background-color: var(--widgets);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.group-icon47,
.union-icon {
  position: relative;
  width: 27.26px;
  height: 19.53px;
}
.union-icon {
  width: 28px;
  height: 18.21px;
}
.bike {
  border-radius: var(--br-3xl);
  background-color: var(--widgets);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.motorbike-child {
  position: relative;
  width: 30px;
  height: 21.46px;
}
.group-icon48,
.group-icon49 {
  position: relative;
  width: 25px;
  height: 29.88px;
}
.group-icon49 {
  width: 28.42px;
  height: 25px;
}
.bus-wrapper {
  flex-direction: row;
  justify-content: flex-start;
}
.bus-wrapper,
.frame-parent36,
.other {
  display: flex;
  align-items: flex-start;
}
.frame-parent36 {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.other {
  flex-direction: column;
  justify-content: center;
}
.drivers-parent {
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: var(--widgets);
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.input-title5 {
  align-self: stretch;
  position: relative;
  display: none;
}
.qwerty111 {
  flex: 1;
  position: relative;
}
.search-icon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.input8,
.qwerty1-group {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.qwerty1-group {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: var(--secondary-1);
}
.input8 {
  flex-direction: column;
  align-items: flex-start;
}
.greece-warning-sign-warning-fo-icon2 {
  position: relative;
  width: 36.71px;
  height: 32px;
  object-fit: cover;
}
.sign {
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.greece-warning-sign-warning-fo-icon4,
.mandatory-direction-ahead-turn-icon {
  position: relative;
  width: 36.24px;
  height: 32px;
  object-fit: cover;
}
.mandatory-direction-ahead-turn-icon {
  width: 32px;
}
.sign-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame-parent37,
.frame-parent38,
.frame-wrapper1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent38 {
  align-items: flex-start;
  gap: var(--gap-mid);
}
.frame-parent37,
.frame-wrapper1 {
  align-items: center;
}
.frame-parent37 {
  gap: var(--gap-13xl);
}
.frame-wrapper1 {
  border-radius: var(--br-base);
  background-color: var(--widgets);
  height: 422px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
}
.frame-parent34,
.frame-parent35,
.progress-bar-parent9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frame-parent35 {
  align-self: stretch;
}
.frame-parent34,
.progress-bar-parent9 {
  width: 782px;
}
.progress-bar-parent9 {
  position: absolute;
  top: 172px;
  left: 334px;
  border-radius: var(--br-base);
  width: 800px;
  justify-content: center;
  text-align: left;
  font-size: var(--small-regular-size);
}
.b18 {
  position: absolute;
  top: 118px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon14 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon50,
.group-icon52 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon52 {
  width: 16px;
}
.frame15,
.menu-overlay14 {
  position: absolute;
  overflow: hidden;
}
.frame15 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay14 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue17 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button20 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.accident-item,
.button-wrapper11 {
  position: absolute;
  left: 0;
  width: 1440px;
}
.button-wrapper11 {
  top: 897px;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.accident-item {
  top: 83px;
  background-color: var(--overlay);
  height: 909px;
}
.iconmilerejected {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
}
.div68,
.div69 {
  position: relative;
}
.div68 {
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 343px;
}
.div69 {
  align-self: stretch;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.vector-icon19,
.vector-icon20,
.vector-icon21,
.vector-icon22,
.vector-icon23 {
  position: absolute;
  height: 16.58%;
  width: 12.99%;
  top: 84.77%;
  right: 81.11%;
  bottom: -1.35%;
  left: 5.9%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon20,
.vector-icon21,
.vector-icon22,
.vector-icon23 {
  right: 21.12%;
  left: 65.89%;
}
.vector-icon21,
.vector-icon22,
.vector-icon23 {
  height: 10.57%;
  width: 11.1%;
  top: 44.24%;
  right: 14.04%;
  bottom: 45.19%;
  left: 74.85%;
}
.vector-icon22,
.vector-icon23 {
  right: 90.03%;
  left: -1.14%;
}
.vector-icon23 {
  height: 63.73%;
  width: 72.92%;
  top: 27.5%;
  right: 21.05%;
  bottom: 8.77%;
  left: 6.03%;
}
.vector-icon24,
.vector-icon25,
.vector-icon26,
.vector-icon27,
.vector-icon28 {
  position: absolute;
  height: 2.69%;
  width: 70.65%;
  top: 80.12%;
  right: 22.28%;
  bottom: 17.18%;
  left: 7.07%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon25,
.vector-icon26,
.vector-icon27,
.vector-icon28 {
  height: 11.63%;
  width: 16.64%;
  top: 64.21%;
  right: 22.84%;
  bottom: 24.16%;
  left: 60.52%;
}
.vector-icon26,
.vector-icon27,
.vector-icon28 {
  right: 75.7%;
  left: 7.66%;
}
.vector-icon27,
.vector-icon28 {
  height: 2.69%;
  width: 67.19%;
  top: 52.12%;
  right: 24.01%;
  bottom: 45.19%;
  left: 8.8%;
}
.vector-icon28 {
  height: 10.09%;
  width: 30.2%;
  top: 64.73%;
  right: 42.56%;
  bottom: 25.18%;
  left: 27.24%;
}
.group-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-steelblue);
}
.b19,
.ellipse-parent {
  position: absolute;
  top: 2.55%;
  left: 20.09%;
}
.ellipse-parent {
  height: 60.63%;
  width: 51.19%;
  top: 0;
  right: 0;
  bottom: 39.37%;
  left: 48.81%;
}
.group1 {
  position: relative;
  width: 52px;
  height: 52px;
}
.group-wrapper2 {
  height: 72px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.div70 {
  flex: 1;
  position: relative;
  font-size: var(--xsmall-regular-size);
  color: var(--main);
}
.frame-parent41 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.group-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-firebrick);
}
.b20 {
  position: absolute;
  top: 2.55%;
  left: 24.53%;
}
.frame-child33 {
  position: relative;
  width: 64px;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent40,
.frame-parent43,
.parent7 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.frame-parent43 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-9xs);
  font-size: var(--xsmall-regular-size);
  color: var(--main);
}
.frame-parent40,
.parent7 {
  flex-direction: column;
}
.frame-parent40 {
  align-items: flex-start;
  gap: var(--gap-9xs);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--widgets);
}
.parent7 {
  align-items: center;
  gap: var(--gap-5xs);
}
.button21,
.frame-parent39 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button21 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  cursor: pointer;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.frame-parent39 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-13xl);
}
.iconmilerejected-parent {
  position: absolute;
  top: 248px;
  left: 420px;
  border-radius: var(--br-base);
  background-color: var(--widgets);
  width: 600px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base);
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-15xl);
}
.accident13 {
  position: relative;
  background-color: var(--background);
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
