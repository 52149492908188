.b43 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon38 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon136,
.group-icon138 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon138 {
  width: 16px;
}
.frame41,
.menu-overlay38 {
  position: absolute;
  overflow: hidden;
}
.frame41 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay38 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.div198,
.frame-child75 {
  position: relative;
}
.frame-child75 {
  width: 16px;
  height: 16px;
}
.button-wrapper34,
.button59 {
  display: flex;
  align-items: center;
}
.button59 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  width: 343px;
  height: 55px;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
}
.button-wrapper34 {
  position: absolute;
  top: 905px;
  left: 521px;
  background-color: var(--background);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-sm);
  justify-content: flex-end;
  font-size: var(--small-regular-size);
  color: var(--widgets);
}
.b44,
.iconyes4 {
  position: relative;
}
.iconyes4 {
  width: 64px;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.b44 {
  
  font-family: var(--m3-label-large);
}
.frame-child76 {
  position: relative;
  width: 28px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
}
.button60,
.pdf-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pdf-group {
  align-items: center;
  gap: var(--gap-5xs);
}
.button60 {
  border-radius: var(--br-7xs);
  width: 194px;
  padding: var(--padding-sm) var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  cursor: pointer;
  font-size: var(--small-regular-size);
  color: var(--highlights);
}
.continue47 {
  flex: 1;
  position: relative;
}
.button61,
.button62,
.share2 {
  flex-direction: row;
  justify-content: center;
}
.button61 {
  flex: 1;
  border-radius: var(--br-7xs);
  background-color: var(--secondary-2);
  display: none;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
}
.button62,
.share2 {
  display: flex;
}
.button62 {
  flex: 1;
  border-radius: var(--br-7xs);
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  font-size: var(--small-regular-size);
  color: var(--highlights);
}
.share2 {
  width: 107px;
  align-items: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.iconyes-parent2,
.parent39 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.parent39 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.iconyes-parent2 {

  top: 172px;
  left: 320px;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
    box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xl);
  font-size: var(--font-size-lgi);
}
.sent {
  position: relative;
  background-color: var(--background);
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
@media only screen and (max-width: 400px) {
.b44{
  font-size: 15px;
}
}