.progress-bar-child169,
.progress-bar-child170 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child170 {
  background-color: var(--secondary-2);
}
.progress-bar40 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div356,
.iconcar-empty4 {
  position: relative;
  flex-shrink: 0;
}
.div356 {
  align-self: stretch;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  height: 29px;
}
.iconcar-empty4 {
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.div357 {
  flex: 1;
  position: relative;
}
.iconcar-empty-parent2 {
  width: 269px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.radio-icon26 {
  position: relative;
  width: 20px;
  height: 20px;
}
.frame-parent164 {
  cursor: pointer;
}
.frame-parent164,
.frame-parent165 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: center;
  justify-content: space-between;
}
.frame-parent163 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-parent169,
.iconcar-empty-parent6 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconcar-empty-parent6 {
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-parent169 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  width: 396px;
  padding: var(--padding-base);
  box-sizing: border-box;
  justify-content: space-between;
}
.frame-parent162,
.frame-wrapper19 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent162 {
  flex-direction: column;
  gap: var(--gap-5xs);
  text-align: left;
  margin:auto;
  font-size: var(--medium-regular-size);
}
.progress-bar-parent34 {
  top: 172px;
  left: 320px;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.b75 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon63 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon208,
.group-icon210 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon210 {
  width: 16px;
}
.frame65,
.menu-overlay62 {
  position: absolute;
  overflow: hidden;
}
.frame65 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay62 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue76 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button101 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.button-wrapper54,
.nd-driver10 {
  background-color: var(--background);
}
.button-wrapper54 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.nd-driver10 {
  position: relative;
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
