.progress-bar-child93,
.progress-bar-child95 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child95 {
  background-color: var(--secondary-2);
}
.progress-bar16 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div93 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.div93,
.pin1,
.text7 {
  align-self: stretch;
}
.pin1 {
  position: relative;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.text7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.trip-map-1-icon5 {
  position: absolute;
  width: 103.13%;
  top: calc(50% - 246.5px);
  right: -0.57%;
  left: -2.56%;
  max-width: 100%;
  overflow: hidden;
  height: 647px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-60912 {
  position: absolute;
  top: 4px;
  left: -9px;
  width: 354px;
  height: 421px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-60913 {
  position: absolute;
  top: 0;
  left: 0;
  width: 800px;
  height: 496px;
  object-fit: cover;
}
.pin-1-icon3 {
  position: absolute;
  top: 256px;
  left: 385px;
  width: 30px;
  height: 38px;
  overflow: hidden;
}
.chalandri-ag6 {
  position: relative;
}
.trip-locations6 {
  position: absolute;
  top: 8px;
  left: 10px;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.trip-map-1-parent3 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  height: 343px;
  overflow: hidden;
  flex-shrink: 0;
}
.open-street-map {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  height: 36.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.input-title9 {
  position: relative;
  display: inline-block;
  width: 295px;
}
.input13 {
  flex: 1;
  position: relative;
  opacity: 0;
}
.input-wrapper8,
.input12 {
  align-self: stretch;

  justify-content: flex-start;
}
.input-wrapper8 {
  width: 100% !important;
  margin-top: 10px;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
  color: #208ca7;
}
.input12 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.input-wrapper7 {
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
}
.frame-parent60,
.input-wrapper7,
.progress-bar-parent14 {
  display: flex;
  flex-direction: column;

}
.frame-parent60 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--small-regular-size);
}
.progress-bar-parent14 {
  top: 172px;
  left: 320px;
  border-radius: var(--br-base);
  justify-content: center;
  gap: var(--gap-5xl);
}
.b32 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon19 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon79,
.group-icon81 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon81 {
  width: 16px;
}
.frame22,
.menu-overlay19 {
  position: absolute;
  overflow: hidden;
}
.frame22 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay19 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue23 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button26 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.accident18,
.button-wrapper16 {
  background-color: var(--background);
}
.button-wrapper16 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.accident18 {
  position: relative;
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
.button173 {
  margin-top: 10px;
  cursor: pointer;
  border-radius: var(--br-7xs);
  background-color: #b80808;
  flex-direction: row;
  padding: 8px;
  justify-content: center;
}
.button-wrapper138 {
  background-color: var(--background);

  flex-direction: row-reverse;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  justify-content: flex-start;
  color: var(--widgets);
}