.b {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon,
.group-icon2 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon2 {
  width: 16px;
}
.frame,
.menu-overlay {
  position: absolute;
  overflow: hidden;
}
.frame {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.div {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  height: 18px;
}
.frame-child {
  position: relative;
  width: 20px;
  height: 20px;
}
.parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--large-bold-size);
  color: var(--highlights);
}
.qwerty {
  position: relative;
  font-weight: 600;
}
.frame-item {
  position: relative;
  width: 15px;
  height: 15px;
}
.summary-inner,
.summary1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.summary1 {
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--small-regular-size);
}
.information,
.title {
  position: relative;
}
.information {
  font-size: var(--small-regular-size);
}
.summary-parent,
.summary2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary2 {
  width: 240px;
  flex-direction: column;
}
.summary-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
}
.statementcategoryinfo,
.summary10,
.summary5 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.summary5 {
  align-self: stretch;
  display: none;
}
.statementcategoryinfo,
.summary10 {
  display: flex;
}
.statementcategoryinfo {
  width: 100%;
  margin-top: 10px;
  gap: var(--gap-5xs);
}
.summary10 {
  align-self: stretch;
  width: 240px;
}
.information8 {
  align-self: stretch;
  position: relative;
  font-size: var(--small-regular-size);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 17px;
  flex-shrink: 0;
}
.frame-parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
}
.information12 {
  position: relative;
  font-size: var(--small-regular-size);
  color: var(--main);
}
.summary-parent1,
.summary17 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary17 {
  width: 240px;
  flex-direction: column;
  color: var(--main);
}
.summary-parent1 {
  flex-direction: row;
  gap: var(--gap-5xl);
  color: var(--black);
}
.statementcategoryinfo2,
.summary-parent2,
.summary23 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.statementcategoryinfo2 {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.summary-parent2,
.summary23 {
  justify-content: space-between;
}
.summary23 {
  width: 240px;
  flex-direction: column;
}
.summary-parent2 {
  flex-direction: row;
}
.summary27,
.summary29 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary29 {
  align-self: stretch;
}
.qwerty5 {
  flex: 1;
  position: relative;
  font-weight: 600;
}
.summary34 {
 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--small-regular-size);
}
.title28 {
  position: relative;
  display: none;
}
.information28 {
  align-self: stretch;
  position: relative;
  font-size: var(--medium-regular-size);
  color: var(--main);
}
.summary35,
.summary36 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary35 {
  align-self: stretch;
  display: flex;
  color: var(--black);
}
.summary36 {
  height: 31px;
  display: none;
}
.information31 {
  position: relative;
  font-size: var(--small-regular-size);
  display: none;
}
.statementcategoryinfo5 {
  align-self: stretch;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.icondelete {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.icondelete-wrapper,
.photo-inner {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.icondelete-wrapper {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  padding: var(--padding-9xs);
  box-sizing: border-box;
}
.photo-inner {
  width: auto;
  height: 150px;

}
.photo,
.photo1 {
  width: 120px;
  height: 112px;
  flex-direction: column;
  align-items: flex-end;
  transform: rotate(180deg);
  transform-origin: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.photo {
  display: flex;
  justify-content: flex-start;
  background-image: url(/public/photo3@3x.png);
}
.photo1 {
  background-image: url(/public/photo11@3x.png);
}
.other-car,
.photo1,
.summary41,
.summary45 {
  display: flex;
  justify-content: flex-start;
}
.summary41 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}
.other-car,
.summary45 {
  flex-direction: column;
  align-items: flex-start;
}
.summary45 {
  align-self: stretch;
}
.other-car {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  width: 800px;
  padding: var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-29xl);
}
.summary54 {
  align-self: stretch;
  width: 240px;
  flex-direction: column;
  color: var(--main);
}
.statementcategoryinfo8,
.summary-parent5,
.summary54 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary-parent5 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-5xl);
  color: var(--black);
}
.statementcategoryinfo8 {
  width: 768px;
  height: 56px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.summary58 {
  align-self: stretch;
  width: 197px;
  flex-direction: column;
}
.statementcategoryinfo-inner,
.summary-wrapper,
.summary58 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary-wrapper {
  align-self: stretch;
  flex-direction: row;
}
.statementcategoryinfo-inner {
  width: 768px;
  flex-direction: column;
  color: var(--black);
}
.summary61 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--small-regular-size);
}
.screenshot-2022-12-08-at-609 {
  top: 4px;
  left: 0;
  width: 250px;
  object-fit: cover;
}
.screenshot-2022-12-08-at-6091 {
  position: absolute;
  top: calc(50% - 224px);
  left: calc(50% - 379.5px);
  width: 761px;
  height: 804px;
  object-fit: cover;
}
.vector-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -0.01%;
  right: 0;
  bottom: 0.01%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group-icon3,
.vector-icon1,
.vector-icon2,
.vector-icon3,
.vector-icon4 {
  position: absolute;
  height: 152.46%;
  width: 97.12%;
  top: -96.47%;
  right: -5.35%;
  bottom: 44.01%;
  left: 8.23%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group-icon3,
.vector-icon2,
.vector-icon3,
.vector-icon4 {
  height: 72.43%;
  width: 55.39%;
  top: -32.44%;
  right: 24.27%;
  bottom: 60%;
  left: 20.34%;
}
.group-icon3,
.vector-icon3,
.vector-icon4 {
  height: 73.04%;
  width: 42.08%;
  top: -81.45%;
  right: 13.05%;
  bottom: 108.42%;
  left: 44.87%;
}
.group-icon3,
.vector-icon4 {
  height: 93.57%;
  width: 53.49%;
  top: -68.35%;
  right: 20.43%;
  bottom: 74.78%;
  left: 26.08%;
}
.group-icon3 {
  height: 78.66%;
  width: 43.41%;
  top: -114.24%;
  right: -5.71%;
  bottom: 135.58%;
  left: 62.3%;
}
.b1,
.frame1 {
  position: absolute;
  top: 35.49%;
  left: 63.47%;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.frame1 {
  top: 288.12px;
  left: 305px;
  width: 46.22px;
  height: 26.41px;
  overflow: hidden;
  transform: rotate(-45deg);
}
.trip-locations {
  position: absolute;
  top: 8px;
  left: 10px;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--small-regular-size);
  color: var(--main);
}
.insideicon {
  position: absolute;
  top: 50.01px;
  left: 331.85px;
  width: 20px;
  height: 20px;
  display: none;
}
.greece-warning-sign-warning-fo-icon {
  position: absolute;
  top: calc(50% + 40px);
  left: calc(50% - 20.5px);
  width: 48px;
  height: 40px;
  object-fit: cover;
}
.greece-warning-sign-warning-fo-icon1 {
  position: absolute;
  top: calc(50% - 18.37px);
  left: calc(50% + 34.49px);
  width: 63.01px;
  height: 60.73px;
  object-fit: cover;
}
.frame-icon1 {
  position: absolute;
  top: calc(50% - 51px);
  left: calc(50% + 65.02px);
  width: 53.29px;
  height: 51.36px;
  overflow: hidden;
}
.frame-icon2,
.frame-icon3,
.frame-icon4 {
  position: absolute;
  top: calc(50% + 110px);
  left: calc(50% - 154.5px);
  width: 81.32px;
  height: 81.32px;
  overflow: hidden;
}
.frame-icon3,
.frame-icon4 {
  top: calc(50% - 52px);
  left: calc(50% - 96.85px);
  width: 32.5px;
  height: 31.32px;
}
.frame-icon4 {
  top: calc(50% - 84.03px);
  left: calc(50% - 46.55px);
  width: 37.42px;
  height: 36.06px;
}
.frame-child10,
.frame-icon5 {
  position: absolute;
  top: calc(50% + 67.5px);
  left: calc(50% + 30.28px);
  width: 9.3px;
  height: 28.45px;
}
.frame-icon5 {
  top: calc(50% - 128.44px);
  left: calc(50% - 142.96px);
  width: 70.44px;
  height: 67.88px;
  overflow: hidden;
}
.group-icon4,
.vector-icon6,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  position: absolute;
  height: 153.29%;
  width: 97.12%;
  top: -167.69%;
  right: 58%;
  bottom: 114.4%;
  left: -55.12%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group-icon4,
.vector-icon7,
.vector-icon8,
.vector-icon9 {
  height: 96.94%;
  width: 42.63%;
  top: -132.53%;
  right: 75.91%;
  bottom: 135.59%;
  left: -18.54%;
}
.group-icon4,
.vector-icon8,
.vector-icon9 {
  height: 73.64%;
  width: 41.73%;
  top: -152.16%;
  right: 104.81%;
  bottom: 178.52%;
  left: -46.54%;
}
.group-icon4,
.vector-icon9 {
  height: 93.6%;
  width: 53.47%;
  top: -139.25%;
  right: 85.59%;
  bottom: 145.64%;
  left: -39.06%;
}
.group-icon4 {
  height: 78.66%;
  width: 43.72%;
  top: -185%;
  right: 121.56%;
  bottom: 206.34%;
  left: -65.28%;
}
.b2,
.car-a {
  position: absolute;
  top: 35.64%;
  left: 65.29%;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.car-a {
  top: 300.36px;
  left: 463.16px;
  width: 46.22px;
  height: 26.41px;
  overflow: hidden;
  transform: rotate(-135deg);
}
.screenshot-2022-12-08-at-609-parent {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  width: 100%;
  height: 448px;
  overflow: hidden;
  flex-shrink: 0;
}
.summary62 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--widgets);
}
.summary63 {
  align-self: stretch;
  display: none;
  align-items: flex-start;
  text-align: justify;
}
.photo2,
.photo3,
.statementcategoryinfo10,
.summary63 {
  flex-direction: column;
  justify-content: flex-start;
}
.statementcategoryinfo10 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
}
.photo2,
.photo3 {
  width: 120px;
  align-items: flex-end;

  transform-origin: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.photo2 {
  display: flex;
  background-image: url(/public/photo21@3x.png);
}
.photo3 {
  display: none;
  background-image: url(/public/photo11@3x.png);
}
.qwerty12 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 304px;
  flex-shrink: 0;
}
.summary71,
.summary72 {
  align-items: flex-start;
  justify-content: flex-start;
}
.summary71 {
  display: flex;
  flex-direction: row;
  gap: var(--gap-5xs);
}
.summary72 {
  display: none;
  flex-direction: column;
  text-align: justify;
  font-size: var(--xsmall-regular-size);
  color: var(--black);
}
.div2 {
  align-self: stretch;
  position: relative;
}
.photo4 {
  width: 120px;
  flex-direction: column;
  align-items: flex-end;
  transform-origin: 0 0;
  background-image: url(/public/photo3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.container,
.photo4,
.photo5,
.summary73 {
  display: flex;
  justify-content: flex-start;
}
.photo5 {
  width: 120px;
  flex-direction: column;
  align-items: flex-end;
  transform-origin: 0 0;
  background-image: url(/public/photo11@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.container,
.summary73 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-9xs);
}
.container {
  width: 240px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.vector-icon10 {
  position: relative;
  border-radius: var(--br-5xs);
  width: 16.93px;
  height: 20px;
}
.play1 {
  border-radius: var(--br-5xl);
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.play-button {
  border-radius: var(--br-9xs);
  background-color: var(--highlights);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-lgi);
  align-items: flex-start;
  justify-content: flex-start;
}
.vector-icon11 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 25px;
}
.play,
.summary74 {
  display: flex;
  justify-content: flex-start;
}
.play {
  align-self: stretch;
  border-radius: var(--br-5xs);
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  text-align: right;
  font-size: var(--medium-regular-size);
  color: var(--main);
}
.summary74 {
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-9xs);
  text-align: justify;
  font-size: var(--xsmall-regular-size);
  color: var(--black);
}
.frame-parent1 {
  flex-direction: row;
  align-items: baseline;
  gap: var(--gap-5xl);
}
.frame-parent1,
.play2,
.summary76 {
  display: flex;
  justify-content: flex-start;
}
.play2 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  text-align: right;
  font-size: var(--medium-regular-size);
}
.summary76 {
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-9xs);
  text-align: justify;
  font-size: var(--xsmall-regular-size);
}
.frame-container {
  width: 606px;
  gap: var(--gap-base);
  font-size: var(--medium-regular-size);
}
.frame-container,
.statementcategoryinfo12,
.summary125 {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.statementcategoryinfo12 {
  align-self: stretch;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--large-bold-size);
}
.summary125 {
  width: 240px;
  opacity: 0;
}
.statementcategoryinfo20,
.statementcategoryinfo24 {
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.statementcategoryinfo20 {
  width: 768px;
}
.statementcategoryinfo24 {
  align-self: stretch;
}
.my-carexpand2,
.other-car-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.my-carexpand2 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: var(--padding-base);
  gap: var(--gap-29xl);
}
.other-car-parent {
  position: absolute;
  top: 172px;
  left: 320px;
  gap: var(--gap-5xs);
  text-align: justify;
  font-size: var(--xsmall-regular-size);
}
.button {
  border-radius: var(--br-7xs);
  background-color: var(--widgets);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 168.5px;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.button,
.button-parent,
.button1 {
  display: flex;
  flex-direction: row;
}
.button1 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  width: 168.5px;
  height: 47px;
  padding: var(--padding-sm) var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--widgets);
}
.button-parent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.summary,
.summary-inner28 {
  background-color: var(--background);
}
.summary-inner28 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1440px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--highlights);
}
.summary {
  position: relative;
  width: 100%;
  height: 992px;
  overflow-y: auto;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
.title {
  font-size: 14px;
  color: #A5A9AC;
}


.container3 {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  }
.summary74 {
  width: 100%;
  display: block;
  transition: all 100ms ease-out;
}
.summary74:hover {
  transform: scale(1.04);
}
@media only screen and (max-width: 400px) {
  .img-fluid {
    max-width:100%;
  }
}
@media only screen and (min-width: 400px) {
  .img-fluid {
    max-width:150px;
  }
}
.div255, .div252 {
  font-size: 15px;}