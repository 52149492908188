.b40 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon36 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon130,
.group-icon132 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon132 {
  width: 16px;
}
.frame39,
.menu-overlay36 {
  position: absolute;
  overflow: hidden;
}
.frame39 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay36 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.div192 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  height: 18px;
}
.frame-child52 {
  position: relative;
  width: 20px;
  height: 20px;
}
.parent31 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--large-bold-size);
  color: var(--highlights);
}
.qwerty30 {
  flex: 1;
  position: relative;
  font-weight: 600;
}
.frame-child53 {
  position: relative;
  width: 15px;
  height: 15px;
}
.summary-inner29,
.summary185 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.summary185 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--small-regular-size);
}
.title151 {
  position: relative;
  display: none;
}
.information142 {
  align-self: stretch;
  position: relative;
  font-size: var(--medium-regular-size);
  color: var(--main);
}
.summary186 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--black);
}
.information143,
.title152 {
  position: relative;
}
.information143 {
  font-size: var(--small-regular-size);
}
.summary187,
.summary188 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.summary187 {
  height: 31px;
}
.summary188 {
  align-self: stretch;
}
.information145 {
  position: relative;
  font-size: var(--small-regular-size);
  display: none;
}
.statementcategoryinfo30 {
  align-self: stretch;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.icondelete27 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.icondelete-wrapper19,
.photo-inner20 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.icondelete-wrapper19 {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  padding: var(--padding-9xs);
  box-sizing: border-box;
}
.photo-inner20 {
  width: 32px;
  height: 32px;
  display: none;
}
.photo21 {
  width: 120px;
  height: 112px;
  flex-direction: column;
  align-items: flex-end;
  transform: rotate(180deg);
  transform-origin: 0 0;
  background-image: url('/public/photo2@3x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.photo21,
.photo22,
.summary192,
.summary196 {
  display: flex;
  justify-content: flex-start;
}
.photo22 {
  width: 120px;
  height: 112px;
  flex-direction: column;
  align-items: flex-end;
  transform: rotate(180deg);
  transform-origin: 0 0;
  background-image: url('/public/photo1@3x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.summary192,
.summary196 {
  align-self: stretch;
}
.summary192 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}
.summary196 {
  flex-direction: column;
  align-items: flex-start;
}
.information151 {
  position: relative;
  font-size: var(--small-regular-size);
  color: var(--main);
}
.other-car2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.other-car2,
.other-car3 {
  border-radius: var(--br-5xs);

  width: 100%;

  box-sizing: border-box;
  gap: 10px;
}
.my-carexpand5,
.other-car-group,
.other-car3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.my-carexpand5 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: var(--padding-base);
  gap: var(--gap-29xl);
}
.other-car-group {
  top: 172px;
  left: 320px;
  gap: var(--gap-5xs);
  text-align: justify;
  font-size: var(--xsmall-regular-size);
}
.button53 {
  border-radius: var(--br-7xs);
  background-color: var(--widgets);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 168.5px;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.button-group,
.button53,
.button54 {
  display: flex;
  flex-direction: row;
}
.button54 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  width: 168.5px;
  height: 47px;
  padding: var(--padding-sm) var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--widgets);
}
.button-group {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.summary-inner44,
.summary184 {
  background-color: var(--background);
}
.summary-inner44 {

  top: 897px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--highlights);
}
.summary184 {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered.css-pwcg7p-MuiCollapse-root{

}
@media only screen and (max-width: 700px) {
  
  .frame-parent156 {
    width: 300px;
    flex-direction: column;
    font-size: var(--small-regular-size);
  
  }
  .summary2{
    width:85vw !important;
    flex-direction: inherit !important;
  }
.summary-parent {
  /* align-self: stretch; */
  flex-direction: column;
  display: block !important;
}
}
.information {
  color: #242A30;
}
.button73:disabled{
  cursor:auto;
  background-color: #E7E8E9 !important;
}
.button73:disabled:Hover{
  cursor:auto;
  color:white !important;
  background-color: #E7E8E9 !important;
}