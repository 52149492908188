.b49,
.wrapper59 {
  align-self: stretch;
}
.b49 {
  position: relative;
  line-height: 100%;
}
.wrapper59 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconwitness {
  position: relative;
  width: 56px;
  height: 56px;
}
.div211 {
  flex: 1;
  position: relative;
}
.frame-child82 {
  position: relative;
  width: 20px;
  height: 20px;
}
.iconwitness-parent {
  width: 688px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.input-title89 {
  align-self: stretch;
  position: relative;
}
.input-wrapper75 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
}
.frame-parent97,
.input-parent20,
.input-wrapper77,
.input160 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input160 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.frame-parent97,
.input-parent20,
.input-wrapper77 {
  flex-direction: row;
}
.input-parent20 {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.frame-parent97,
.input-wrapper77 {
  width: 340px;
}
.frame-parent97 {
  width: 688px;
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--small-regular-size);
}
.div212,
.icondriver12 {
  position: relative;
  flex-shrink: 0;
}
.div212 {
  display: inline-block;
  width: 309px;
  height: 16px;
}
.icondriver12 {
  width: 42px;
  height: 42px;
  overflow: hidden;
}
.icondriver-parent10,
.parent46 {
  align-items: center;
  justify-content: center;
}
.icondriver-parent10 {
  width: 309px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-lg);
}
.parent46 {
  border-radius: var(--br-3xs);
  background-color: var(--widgets);
  display: none;
  flex-direction: column;
  padding: var(--padding-base);
  gap: var(--gap-base);
  text-align: center;
}
.div213 {
  position: relative;
  display: inline-block;
  width: 229px;
}
.wrapper60 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.slider-child10 {
  position: relative;
  border-radius: 50%;
  background-color: var(--widgets);
  width: 18px;
  height: 18px;
}
.slider12 {
  border-radius: var(--br-9xl);
  background-color: var(--highlights);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 56px;
  height: 24px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  align-items: center;
  justify-content: flex-end;
}
.frame-parent98,
.infopersonalexpand6 {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frame-parent98 {
  width: 343px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-xs);
}
.infopersonalexpand6 {
  width: 800px;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-13xl) var(--padding-base);
  gap: var(--gap-base);
}
.no-copy-21 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.list2 {
  border-radius: var(--br-5xs);
  border: 1px dashed var(--secondary-1);
  box-sizing: border-box;
  width: 800px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: center;
  justify-content: center;
}
.infopersonalexpand-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--medium-regular-size);
}
.frame-parent96 {
  position: absolute;
  top: 148px;
  left: 320px;
  border-radius: var(--br-base);
  width: 800px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.b50 {
  position: absolute;
  top: 119px;
  left: 322px;
  font-size: var(--large-bold-size);
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon42 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon148,
.group-icon150 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon150 {
  width: 16px;
}
.frame45,
.menu-overlay42 {
  position: absolute;
  overflow: hidden;
}
.frame45 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay42 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue51 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button71 {
  position: absolute;
  top: 897px;
  left: 555px;
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.witness2 {
  position: relative;
  background-color: var(--background);
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--title-small-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
