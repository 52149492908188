.progress-bar-child87,
.progress-bar-child91 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child91 {
  background-color: var(--secondary-2);
}
.progress-bar15 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div74,
.div75 {
  align-self: stretch;
  position: relative;
}
.div74 {
  line-height: 20px;
  font-weight: 600;
}
.div75 {
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.parent8 {
  gap: var(--gap-5xs);
}
.parent8,
.question {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.a {
  position: relative;
}
.a-wrapper {
  height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame-parent59 {
  padding: 4px 0px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.checkbox-child {
  position: relative;
  border-radius: var(--br-9xs);
  border: 1px solid var(--highlights);
  box-sizing: border-box;
  width: 24px;
  height: 24px;
}
.checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div76 {
  flex: 1;
  position: relative;
}
.list-item {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-parent58,
.list-item-parent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.list-item-parent {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-5xl);
  justify-content: flex-start;
  font-size: var(--xsmall-regular-size);
  color: var(--main);
}
.frame-parent58 {
  font-size: var(--small-regular-size);
  color: var(--black);
}
.frame-parent58,
.progress-bar-parent13,
.question-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.question-parent {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-5xl);
}
.progress-bar-parent13 {
  top: 172px;
  left: 320px;
  align-items: flex-start;
  gap: var(--gap-base);
}
.b31 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon18 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon76,
.group-icon78 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon78 {
  width: 16px;
}
.frame21,
.menu-overlay18 {
  position: absolute;
  overflow: hidden;
}
.frame21 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay18 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue22 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button25 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.accident17,
.button-wrapper15 {
  background-color: var(--background);
}
.button-wrapper15 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.accident17 {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
.form-check-input:checked {
  background-color: #46bedc;
  width: 18px !important;
  height: 18px !important;
  border-color: #46bedc;
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
}
.div76 {
  flex: 1 1;
  font-size: 13px;
  position: relative;
}