.div228 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.div227,
.div228,
.div229 {
  align-self: stretch;
}
.div229 {
  position: relative;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.div227 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--large-bold-size);
}
.qwert30 {
  flex: 1;
  position: relative;
}
.insideicon27 {
  position: relative;
  width: 20px;
  height: 20px;
}
.listnew-statement30 {
  cursor: pointer;
}
.listnew-statement30,
.listnew-statement31 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame-parent112,
.listnew-statement-parent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.listnew-statement-parent4 {
  width: 100%;
  gap: var(--gap-5xs);
}
.frame-parent112 {
  width: 100%;
  gap: var(--gap-13xl);
  text-align: left;
}
.button75 {
  flex: 1;
  border-radius: var(--br-7xs);
  background-color: var(--secondary-2);
  display: none;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
}
.div230 {
  border: none;
  width: 104px;
  position: relative;
}
.wrapper67 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.icondelete38 {
  position: relative;
  width: 15px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent113 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.button-inner5 {
  position: relative;
  min-width: 94px;
  height: 19px;
}
.button76,
.delete7 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button76 {
  flex: 1;
  border-radius: var(--br-7xs);
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  color: var(--secondary-1);
}
#fil-dil{
width:100% !important;
}
.delete7 {
  width: 343px;
  align-items: flex-start;
  gap: var(--gap-5xs);
  color: var(--widgets);
}
.b56,
.div226 {
  position: absolute;
}
.div226 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.b56 {
  top: 119px;
  left: 322px;
  font-size: var(--small-regular-size);
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon46 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon160,
.group-icon162 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon162 {
  width: 16px;
}
.frame49,
.menu-overlay46 {
  position: absolute;
  overflow: hidden;
}
.frame49 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay46 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue56 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button-wrapper40,
.button77 {
  display: flex;
  align-items: center;
}
.button77 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  justify-content: center;
}
.button-wrapper40 {
  position: absolute;
  top: 897px;
  left: 0;
  background-color: var(--background);
  width: 1440px;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  justify-content: flex-start;
  color: var(--widgets);
}
.statement-intro2 {
  position: relative;
  background-color: var(--background);
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--medium-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
#new{
  margin-top: -3px;
  margin-left: 4px;
}