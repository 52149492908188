.div389,
.div390 {
  align-self: stretch;
  position: relative;
}
.div390 {
  font-size: var(--m3-label-large-size);
  color: var(--secondary-1);
}
.text-inputs {
  width: 351px;
  height: 145px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  text-align: center;
  font-size: var(--large-semibold-size);
  color: var(--main);
  font-family: var(--medium-semibold);
}
.notch-icon1 {
  width: 219px;
  height: 30px;
  position: relative;
  object-fit: cover;
  display: none;
}
.recording-indicator-icon1 {
  width: 6px;
  height: 6px;
  position: relative;
  display: none;
}
.text-inputs-group,
.text-inputs-group1,
.text-inputs-group2 {
  height: 8px;
  margin:2px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: var(--secondary-1);
}
.text-inputs-group2 {
  background-color: var(--highlights);
}
.ellipse-cluster,
.ellipse-cluster-wrapper,
.illustration2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  justify-content: flex-start;
}
.ellipse-cluster {
  gap: var(--gap-8xs);
}
.ellipse-cluster-wrapper,

.illustration2 {
  width: 100%;
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 0 0 var(--padding-36xl);
  box-sizing: border-box;
  gap: var(--gap-41xl);
}
