.b15 {
  position: absolute;
  top: 119px;
  left: 340px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon11 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon35,
.group-icon37 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon37 {
  width: 16px;
}
.frame12,
.menu-overlay11 {
  position: absolute;
  overflow: hidden;
}
.frame12 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay11 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue11 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button-wrapper8,
.button12 {
  display: flex;
  align-items: center;
}
.button12 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  justify-content: center;
}
.button-wrapper8 {
  position: absolute;
  top: 897px;
  left: 0;
  background-color: var(--background);
  width: 1440px;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.progress-bar-child57,
.progress-bar-child62 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child62 {
  background-color: var(--secondary-2);
}
.progress-bar10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div55 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 327px;
}
.title146 {
  width: 343px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.div56 {
  flex: 1;
  position: relative;
}
.wrapper28 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.radio-icon4 {
  position: relative;
  width: 20px;
  height: 20px;
}
.yes2 {
  cursor: pointer;
}
.no2,
.yes2 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: center;
  justify-content: space-between;
}
.progress-bar-parent8,
.yesno8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yesno8 {
  width: 310px;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--medium-regular-size);
}
.progress-bar-parent8 {

  top: 172px;
  left: 320px;

  padding: 0 var(--padding-base);
  box-sizing: border-box;
  justify-content: flex-end;
  gap: var(--gap-base);
}
.accident10 {
  position: relative;
  background-color: var(--background);
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
