.illustration-info-icon {
  height: 290px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.illustration-info-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-9xs);
}
.div387,
.div388 {
  align-self: stretch;
  position: relative;
}
.div388 {
  font-size: var(--m3-label-large-size);
  color: var(--secondary-1);
}
.collect-elements {
  width: 351px;
  height: 145px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  text-align: center;
  font-size: var(--large-semibold-size);
  color: var(--main);
  font-family: var(--medium-semibold);
}
.notch-icon {
  width: 219px;
  height: 30px;
  position: relative;
  object-fit: cover;
  display: none;
}
.recording-indicator-icon {
  width: 6px;
  height: 6px;
  position: relative;
  display: none;
}
.arrow-menu,
.arrow-menu1,
.arrow-menu2 {
  height: 8px;
  width: 8px;
  margin:2px;
  position: relative;
  border-radius: 50%;
  background-color: var(--highlights);
}
.arrow-menu1,
.arrow-menu2 {
  background-color: var(--secondary-1);
}
.ellipse-set,
.ellipse-set-wrapper,
.illustration1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.ellipse-set {
  gap: var(--gap-8xs);
}
.ellipse-set-wrapper,

.illustration1 {
  width: 100%;
  position: relative;
  background-color: var(--m3-sys-light-on-primary);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 0 0 var(--padding-36xl);
  box-sizing: border-box;
  gap: var(--gap-41xl);
  cursor: pointer;
}
