.progress-bar-child161,
.progress-bar-child164 {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-child164 {
  background-color: var(--secondary-2);
}
.progress-bar38 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div352 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.wrapper98 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.div353 {
  flex: 1;
  position: relative;
}
.wrapper99 {
  width: 269px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.radio-icon24 {
  position: relative;
  width: 20px;
  height: 20px;
}
.yes5 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  padding: var(--padding-base);
  gap: var(--gap-3xl);
}
.wrapper100,
.yes5,
.yesno11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.yesno11 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--medium-regular-size);
}
.progress-bar-parent32 {
  top: 148px;
  left: 320px;
  display: flex;
  flex-direction: column;
  
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
}
.b73 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon61 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon202,
.group-icon204 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon204 {
  width: 16px;
}
.frame63,
.menu-overlay60 {
  position: absolute;
  overflow: hidden;
}
.frame63 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay60 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue74 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button99 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.button-wrapper52,
.nd-driver8 {
  background-color: var(--background);
}
.button-wrapper52 {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.nd-driver8 {
  position: relative;
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
