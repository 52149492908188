.onboarding-selector-inner {
  position: relative;
  border-radius: var(--br-5xl);
  width: 44px;
  height: 44px;
}
.div238,
.div239 {
  align-self: stretch;
  position: relative;
}
.div238 {
  font-weight: 600;
}
.div239 {
  margin-top: 10px;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.parent52 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.insideicon34 {
  position: relative;
  width: 20px;
  height: 20px;
}
.button73 {
  border: none !important;
  background-color: #46bedc !important;
}
.onboarding-selector2 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.frame-wrapper15,
.onboarding-selector-frame {
  border-radius: var(--br-base);
  background-color: var(--widgets);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.onboarding-selector-frame {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-7xl) var(--padding-base);
  justify-content: flex-start;
}
.frame-wrapper15 {
  width: 100%;
  justify-content: center;
  cursor: pointer;
}
.onboarding-selector-child1 {
  position: relative;
  border-radius: var(--br-5xl);
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-wrapper16 {
  border-radius: var(--br-base);
  background-color: var(--widgets);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame-parent117 {
  max-width: 100%;
  top: 172px;
  left: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.b58 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  width: 796px;
}
.content {
  text-align: center;

}
.button73{
  border: none !important;
}
.button73:hover{
  background-color:#E7E8E9 !important;
  color:white !important;
}
.arrow-header-2-icon48 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon166,
.group-icon168 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon168 {
  width: 16px;
}
.frame51,
.menu-overlay48 {
  position: absolute;
  overflow: hidden;
}
.frame51 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay48 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.statement-intro4 {
  position: relative;
  background-color: var(--background);
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: left;
  font-size: var(--medium-regular-size);
  color: var(--main);
  font-family: var(--medium-regular);
}
button.button73.btn.btn-primary {
  width: 300px;
}
.modal-footer {
  justify-content: center;
}
.frame-item{
  cursor:auto;
}