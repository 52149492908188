.progress-bar-child,
.progress-bar-inner {
  flex: 1;
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--highlights);
  height: 4px;
}
.progress-bar-inner {
  background-color: var(--secondary-2);
}
.progress-bar {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.div9 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.div9,
.pin,
.text {
  align-self: stretch;
}
.pin {
  position: relative;
  font-size: var(--small-regular-size);
  color: var(--secondary-1);
}
.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.trip-map-1-icon {
  position: absolute;
  width: 103.13%;
  top: calc(50% - 246.5px);
  right: -0.57%;
  left: -2.56%;
  max-width: 100%;
  overflow: hidden;
  height: 647px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-6092 {
  position: absolute;
  top: 4px;
  left: -9px;
  width: 354px;
  height: 421px;
  object-fit: cover;
  display: none;
}
.screenshot-2022-12-08-at-6093 {
  position: absolute;
  top: 0;
  left: 0;
  width: 800px;
  height: 496px;
  object-fit: cover;
}
.pin-1-icon {
  position: absolute;
  top: 256px;
  left: 385px;
  width: 30px;
  height: 38px;
  overflow: hidden;
}
.chalandri-ag1 {
  position: relative;
}
.trip-locations1 {
  position: absolute;
  top: 8px;
  left: 10px;
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.trip-map-1-parent {
  border-radius: var(--br-5xs);
  background-color: var(--widgets);
  height: 343px;
  overflow: hidden;
  flex-shrink: 0;
}
.input-title,
.trip-map-1-parent {
  align-self: stretch;
  position: relative;
}
.input1 {
  flex: 1;
  position: relative;
}
.input,
.input-container {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.input-container {
  border-radius: var(--br-9xs);
  background-color: var(--widgets);
  border: 1px solid var(--secondary-2);
  box-sizing: border-box;
  height: 45px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xs);
  align-items: center;
  font-size: var(--medium-regular-size);
}
.input {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.input-wrapper {
  width: 400px;
  justify-content: flex-end;
}
.frame-parent5,
.input-wrapper,
.progress-bar-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frame-parent5 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--small-regular-size);
}
.progress-bar-parent {
  position: absolute;
  top: 172px;
  left: 320px;
  border-radius: var(--br-base);
  width: 800px;
  justify-content: center;
  gap: var(--gap-5xl);
}
.b3 {
  position: absolute;
  top: 119px;
  left: 322px;
  line-height: 20px;
  display: inline-block;
  width: 796px;
  height: 29px;
}
.arrow-header-2-icon1 {
  position: absolute;
  top: 104px;
  left: 314px;
  width: 44px;
  height: 44px;
  overflow: hidden;
}
.group-icon5,
.group-icon7 {
  position: relative;
  width: 26px;
  height: 4px;
}
.group-icon7 {
  width: 16px;
}
.frame2,
.menu-overlay1 {
  position: absolute;
  overflow: hidden;
}
.frame2 {
  top: 11px;
  left: 9.5px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.menu-overlay1 {
  top: 104px;
  left: 1074.5px;
  width: 44px;
  height: 44px;
}
.continue1 {
  position: relative;
  display: inline-block;
  width: 288px;
  flex-shrink: 0;
}
.button2 {
  border-radius: var(--br-7xs);
  background-color: var(--highlights);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-base);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.accident,
.button-wrapper {
  background-color: var(--background);
}
.button-wrapper {
  position: absolute;
  top: 897px;
  left: 0;
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-3xs) var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--medium-regular-size);
  color: var(--widgets);
}
.accident {
  position: relative;
  width: 100%;
  height: 992px;
  overflow: hidden;
  text-align: center;
  font-size: var(--large-bold-size);
  color: var(--main);
  font-family: var(--medium-regular);
}

